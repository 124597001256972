<template>
    <v-skeleton-loader v-if="fetchingFileVersions" type="article, table"></v-skeleton-loader>
    <v-card flat v-else>
        <v-card-title>
            <span class="secondary--text font-weight-bold">File Compare</span>
        </v-card-title>
        <v-card-subtitle>{{ fileName }}</v-card-subtitle>
        <v-card-text>
            <v-row>
                <v-col cols="6">
                    <v-card flat color="grey lighten-3">
                        <v-card-title class="text-subtitle-1">
                            This version
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" class="ml-1" color="info">mdi-information-outline</v-icon>
                                </template>
                                <span>This is the current version of your file.</span>
                            </v-tooltip>
                        </v-card-title>
                        <v-card-text class="text-body-1">
                            <b>{{ this.$store.getters['snapshotStore/snapshotLongNameById'](this.$route.params.snid) }}</b>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="6">
                    <v-card flat color="grey lighten-3">
                        <v-card-title class="text-subtitle-1">That version</v-card-title>
                        <v-card-text>
                            <div class="d-flex align-center">
                                <v-autocomplete
                                    v-model="selectedSnapshot"
                                    :items="snapshots"
                                    dense
                                    solo
                                    hide-details
                                    return-object
                                    item-text="snapshot_long_id"
                                    label="select snapshot"
                                    :loading="compareLoading"
                                    @change="compareFiles()">
                                    <template v-slot:item="{ item }">
                                        <div class="d-flex align-center w-100">
                                            <div class="mr-5">{{ item.snapshot_long_id }}</div>
                                            <v-spacer></v-spacer>
                                            <div>
                                                <span class="mr-5" v-if="!isColdStorage(item.snid)">
                                                    ({{ item.last_modified_timestamp | dateTimeToHuman }})
                                                </span>
                                                <v-chip v-if="isColdStorage(item.snid)" small outlined color="primary">cold storage</v-chip>
                                                <v-chip v-else small outlined>filesystem</v-chip>
                                            </div>
                                        </div>
                                    </template>
                                </v-autocomplete>
                                <v-dialog v-model="restoreDialog" max-width="500">
                                    <template v-slot:activator="{ on: dialog }">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on: tooltip }">
                                                <v-btn
                                                    color="error"
                                                    class="ml-2"
                                                    icon
                                                    v-on="{ ...tooltip, ...dialog }"
                                                    :disabled="disableRestore || selectedSnapshot === null"
                                                    :loading="restoreLoading">
                                                    <v-icon>mdi-restore-alert</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Restore</span>
                                        </v-tooltip>
                                    </template>
                                    <v-card>
                                        <v-card-title>
                                            <div class="secondary--text">
                                                <v-icon class="mr-1">mdi-restore-alert</v-icon>
                                                File Restore
                                            </div>
                                        </v-card-title>
                                        <v-divider class="mb-1"></v-divider>
                                        <v-card-text>
                                            Restoring a previous version of a file will replace the current version of the file in your current state with the
                                            previous.
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="error" text @click="restoreFile()">restore this version</v-btn>
                                            <v-btn color="secondary" text @click="restoreDialog = false">cancel</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <div v-if="diffs" v-html="prettyHtml" />
            <v-alert prominent type="info" text class="mt-5" dense v-else-if="selectedSnapshot !== null && !compareError && !compareLoading">
                <v-row align="center">
                    <v-col class="grow">The file version you have selected is identical to what you currently have.</v-col>
                    <v-col class="shrink">
                        <v-btn
                            :to="{
                                name: 'snapshot-files',
                                params: {
                                    oid: $route.params.oid,
                                    sid: $route.params.sid,
                                    iid: $route.params.iid,
                                    snid: $route.params.snid,
                                    localPath: currentFileLocalPath,
                                    fileArea: fileAreaType
                                }
                            }"
                            text
                            exact
                            color="secondary">
                            go back to files
                        </v-btn>
                    </v-col>
                </v-row>
            </v-alert>
            <v-alert prominent type="warning" text class="mt-5" dense v-else-if="selectedSnapshot !== null && compareError && !compareLoading">
                <v-row align="center">
                    <v-col class="grow">{{ compareErrorMessage }}</v-col>
                    <v-col class="shrink">
                        <v-btn
                            :to="{
                                name: 'snapshot-files',
                                params: {
                                    oid: $route.params.oid,
                                    sid: $route.params.sid,
                                    iid: $route.params.iid,
                                    snid: $route.params.snid,
                                    localPath: currentFileLocalPath,
                                    fileArea: fileAreaType
                                }
                            }"
                            text
                            exact
                            color="secondary">
                            go back to files
                        </v-btn>
                    </v-col>
                </v-row>
            </v-alert>
        </v-card-text>
    </v-card>
</template>

<script>
import { Diff2Html } from 'diff2html'
import 'diff2html/dist/diff2html.min.css'
import { mapGetters, mapState } from 'vuex'

export default {
    data() {
        return {
            diffs: '',
            selectedSnapshot: null,
            disableRestore: false,
            restoreLoading: false,
            restoreDialog: false,
            compareError: false,
            compareErrorMessage: '',
            compareLoading: false
        }
    },
    methods: {
        restoreFile() {
            this.restoreLoading = true
            this.restoreDialog = false
            const oldFidToRestore = this.currentFileUnderRevisionData.previousVersions.filter(
                snapshot => snapshot.snapshot_long_id === this.selectedSnapshot.snapshot_long_id
            )[0].fid
            this.$axios
                .post(`/files/${this.currentFileUnderRevisionData.fid}/restore_version/${oldFidToRestore}`)
                .then(response => {
                    this.$router.push({
                        name: 'snapshot-files',
                        params: {
                            oid: this.$route.params.oid,
                            sid: this.$route.params.sid,
                            iid: this.$route.params.iid,
                            snid: this.$route.params.snid,
                            localPath: this.currentFileLocalPath,
                            fileArea: this.fileAreaType
                        }
                    })
                    this.dRestore = true
                    this.$store.dispatch('snapshotStore/setCurrentFileUnderRevisionData', {
                        fid: oldFidToRestore
                    })
                    this.$store.dispatch('showSnackBar', { snackBarText: 'File successfully restored!', snackBarTimeout: 5000, snackBarIcon: 'check_circle' })
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', { snackBarText: 'Could not restore file!', snackBarTimeout: 10000, snackBarIcon: 'error' })
                })
                .finally(() => {
                    this.restoreLoading = false
                })
        },
        async compareFiles() {
            if (this.selectedSnapshot === null) return
            this.compareLoading = true
            this.compareError = false
            const fid = this.currentFileUnderRevisionData.previousVersions.filter(
                snapshot => snapshot.snapshot_long_id === this.selectedSnapshot.snapshot_long_id
            )[0].fid

            try {
                const { data } = await this.$axios.post(`/files/${this.currentFileUnderRevisionData.fid}/diff_to/${fid}`)
                this.diffs = data
            } catch (error) {
                this.compareError = true
                if (error.response?.data?.code === 'file_too_big') {
                    this.compareErrorMessage = 'File is too big for diffing.'
                } else if (error.response?.data?.code === 'not_file') {
                    this.compareErrorMessage = 'The file selected for diffing is not a file, or it does not exist in the backup.'
                } else if (error.response?.data?.code === 'not_text_file') {
                    this.compareErrorMessage = 'File is binary, cannot diff it.'
                } else {
                    this.compareErrorMessage = error.message
                }
            } finally {
                this.compareLoading = false
            }
        },
        isColdStorage(snid) {
            return this.$store.getters['snapshotStore/isColdStorage'](snid)
        }
    },
    computed: {
        ...mapState('snapshotStore', ['currentFileUnderRevisionData', 'fileAreaType', 'fetchingFileVersions']),
        ...mapGetters('snapshotStore', ['currentFileLocalPath', 'developmentSnapshot', 'nonDevelopmentSnapshots']),
        snapshots() {
            return this.currentFileUnderRevisionData.previousVersions
        },
        prettyHtml() {
            return Diff2Html.getPrettyHtml(this.diffs, {
                inputFormat: 'diff',
                showFiles: true,
                matching: 'lines',
                outputFormat: 'side-by-side',
                renderNothingWhenEmpty: false
            })
        },
        fileName() {
            if (this.isColdStorage(this.$route.params.snid)) {
                let splittedPath = this.filePath.split('/')
                splittedPath = splittedPath.slice(3, splittedPath.length)
                return splittedPath.join('/')
            } else {
                return this.filePath
            }
        },
        filePath() {
            return window.atob(this.$route.params.fid).replace(/^nvf:\/\/[0-9]+/, '')
        }
    },
    created() {
        this.$store.dispatch('snapshotStore/setCurrentFileUnderRevisionData', { fid: this.$route.params.fid }).finally(() => {
            if (this.currentFileUnderRevisionData.previousVersions.length > 0) {
                this.selectedSnapshot = this.currentFileUnderRevisionData.previousVersions[0]
                this.compareFiles()
            }
        })
    }
}
</script>
